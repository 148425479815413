<template>
    <v-container class="fill-height justify-center" fluid>
        <p class="headline">{{ $t(`messages['no permission']`) }}</p>
    </v-container>
</template>

<script>
export default {
    name: "login",
    layout: "default",
    head() {
        return {
            title: this.$t("titles.forbidden"),
            toolbar: this.$t("toolbar.forbidden"),
        }
    },
}
</script>
